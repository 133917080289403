import React from "react";
import ProgressStatusBar from "../../RaffleComponents/ProgressStatusBar";
import RaffleSalesByShliachChart from "./RaffleSalesByShliachChart";
import RaffleSalesOverTimeChart from "./RaffleSalesOverTimeChart";
import Loader from "../../../common/Loader";
import RafflesApi from "../../../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import { formatCurrency, formatNumber } from "../../../../lib";
import TicketMetricsCards from "./TicketMetricsCards";
import YearOverYearComparisonIntervalChart from "../../../shared/YearOverYearComparisonIntervalChart";

const ticketQuantityDetails = {
  type: "ticket",
  label: "Show number of tickets sold",
};

export default class RaffleMetrics extends React.PureComponent {
  constructor(props) {
    super();

    const {
      raffleDetails: { isOpenForManualTicketEntry },
    } = props;
    this.state = {
      metrics: {},
      metricsErrorMessage: "",
      metricsLoading: false,
      salesOverTime: [],
      salesOverTimeErrorMessage: "",
      salesOverTimeLoading: false,
      salesOverTimeTimeFrame: !isOpenForManualTicketEntry ? "all" : "last7Days",
      salesOverTimeComparison: [],
      salesOverTimeComparisonErrorMessage: "",
      salesOverTimeComparisonLoading: false,
      salesOverTimeComparisonIncludeAllEvents: false,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getMetrics();
    this.getSalesOverTime();
    this.getSalesOverTimeComparison();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getMetrics = () => {
    this.setState(
      {
        metricsErrorMessage: "",
        metricsLoading: true,
      },
      () => {
        RafflesApi.getRaffleMetrics(this.apiSignal.token, this.props.raffleId)
          .then((metrics) => {
            this.setState({
              metricsLoading: false,
              metrics,
            });
            this.getSalesOverTime();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                metricsErrorMessage: ApiCallErrorMessageHandler(err),
                metricsLoading: false,
              });
            }
          });
      }
    );
  };

  getSalesOverTime = (timeFrame = this.state.salesOverTimeTimeFrame) => {
    this.setState(
      {
        salesOverTimeErrorMessage: "",
        salesOverTimeLoading: true,
        salesOverTimeTimeFrame: timeFrame,
      },
      () => {
        RafflesApi.getRaffleSalesOverTime(
          this.apiSignal.token,
          this.props.raffleId,
          timeFrame
        )
          .then((salesOverTime) => {
            this.setState({
              salesOverTimeLoading: false,
              salesOverTime,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                salesOverTimeErrorMessage: ApiCallErrorMessageHandler(err),
                salesOverTimeLoading: false,
              });
            }
          });
      }
    );
  };

  getSalesOverTimeComparison = (
    includeAllEvents = this.state.salesOverTimeComparisonIncludeAllEvents
  ) => {
    this.setState(
      {
        salesOverTimeComparisonErrorMessage: "",
        salesOverTimeComparisonLoading: true,
        salesOverTimeComparisonIncludeAllEvents: includeAllEvents,
      },
      () => {
        RafflesApi.getRaffleSalesOverTimeComparison(
          this.apiSignal.token,
          this.props.raffleId,
          includeAllEvents
        )
          .then((salesOverTimeComparison) => {
            this.setState({
              salesOverTimeComparisonLoading: false,
              salesOverTimeComparison,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                salesOverTimeComparisonErrorMessage:
                  ApiCallErrorMessageHandler(err),
                salesOverTimeComparisonLoading: false,
              });
            }
          });
      }
    );
  };
  render() {
    const {
      raffleDetails: {
        daysRemaining,
        endDate,
        isOpenForManualTicketEntry,
        raffleTitle,
        startDate,
      },
      systemRaffleDaysToEnd,
      toOrdersTab,
    } = this.props;

    const {
      metrics,
      metrics: {
        cociOwes,
        chabadHousesOwe,
        shliachSales,
        totalCcFees,
        totalCociFees,
        totalInFees,
        totalProcessingFeeDonorAmountRaised,
        totalRaised,
        totalRaisedByReferrers,
        totalTicketAmountRaised,
      },
      metricsErrorMessage,
      metricsLoading,
      salesOverTime,
      salesOverTimeErrorMessage,
      salesOverTimeLoading,
      salesOverTimeTimeFrame,
      salesOverTimeComparison,
      salesOverTimeComparisonErrorMessage,
      salesOverTimeComparisonLoading,
      salesOverTimeComparisonIncludeAllEvents,
    } = this.state;

    const currencyCode = "USD";

    return (
      <div className="card raffle-details-card raffle-metrics-card">
        <div className="flex flex-justify-space flex-align-center mb-24 all-shluchim-header">
          <p className="xxl-text fw-500">Metrics - All Shluchim</p>
        </div>
        {metricsLoading ? (
          <Loader />
        ) : metricsErrorMessage ? (
          <div>{metricsErrorMessage}</div>
        ) : (
          <React.Fragment>
            <div>
              <div className="flex flex-justify-space">
                <p className="medium-text fw-700">Grand Draw Progress</p>
                {!!daysRemaining && (
                  <p className="medium-text fw-700">
                    {formatNumber(daysRemaining)} Day
                    {daysRemaining === 1 ? "" : "s"} Remaining
                  </p>
                )}
              </div>
              <ProgressStatusBar
                className="mt-8"
                daysRemaining={daysRemaining}
                endDate={endDate}
                startDate={startDate}
                type="raffleDays"
              />
            </div>
            <div className="money-status-cards-container">
              <div className="money-status-card relative" onClick={toOrdersTab}>
                <p className="mb-8">
                  {formatCurrency(totalRaised, currencyCode)}
                </p>
                <p>Total Raised</p>
                <div className="money-status-card-substatus">
                  <div>
                    {formatCurrency(totalTicketAmountRaised, currencyCode)}{" "}
                    Ticket Costs
                  </div>
                  <div>
                    {formatCurrency(
                      totalProcessingFeeDonorAmountRaised,
                      currencyCode
                    )}{" "}
                    Donated Processing Fees
                  </div>
                  <div>
                    {formatCurrency(totalRaisedByReferrers, currencyCode)} Total
                    Raised by Teams
                  </div>
                </div>
              </div>
              <div className="money-status-card relative" onClick={toOrdersTab}>
                <p className="mb-8">
                  {formatCurrency(totalInFees, currencyCode)}
                </p>
                <p>Total in Fees</p>
                <div className="money-status-card-substatus">
                  <div>
                    {formatCurrency(totalCociFees, currencyCode)} Total Coci
                    Ticket Fees
                  </div>
                  <div>
                    {formatCurrency(totalCcFees, currencyCode)} Total Processing
                    Fees
                  </div>
                </div>
              </div>
              <div className="money-status-card" onClick={toOrdersTab}>
                <p className="mb-8">{formatCurrency(cociOwes, currencyCode)}</p>
                <p>COCI Owes Shluchim</p>
              </div>
              <div className="money-status-card" onClick={toOrdersTab}>
                <p className="mb-8">
                  {formatCurrency(chabadHousesOwe, currencyCode)}
                </p>
                <p>Shluchim Owe COCI</p>
              </div>
            </div>
            <div className="mt-32 flex">
              <TicketMetricsCards {...metrics} />
              <RaffleSalesByShliachChart data={shliachSales} />
            </div>

            <RaffleSalesOverTimeChart
              allowTimeFrameSelection={isOpenForManualTicketEntry}
              currencyCode={currencyCode}
              data={salesOverTime}
              errorMessage={salesOverTimeErrorMessage}
              getSalesOverTime={this.getSalesOverTime}
              loading={salesOverTimeLoading}
              timeFrame={salesOverTimeTimeFrame}
            />

            <YearOverYearComparisonIntervalChart
              useCurrency={true}
              chartTitle="Sales Over Time Year to Year Comparison"
              endDateDescription="Grand draw end"
              eventType="raffle"
              eventTitle={raffleTitle}
              currencyCode={currencyCode}
              data={salesOverTimeComparison}
              daysRemaining={daysRemaining}
              errorMessage={salesOverTimeComparisonErrorMessage}
              getData={this.getSalesOverTimeComparison}
              loading={salesOverTimeComparisonLoading}
              includeAllEvents={salesOverTimeComparisonIncludeAllEvents}
              intervals={systemRaffleDaysToEnd}
              showQuantityOption={true}
              quantityDetails={ticketQuantityDetails}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
